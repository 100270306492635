import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "normalize.css"

import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

//import inViewportDirective from "vue-in-viewport-directive";
//Vue.directive("in-viewport", inViewportDirective);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')