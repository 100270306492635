<template>
  <div id="app">

    <transition name="fade">
      <div id="mySidenav" class="sidenav" :style="" v-show="this.side_nav_open">
        <!-- <a
          href="javascript:void(0)"
          class="closebtn"
          v-on:click="side_nav_open = false"
          >&times;</a
        > -->
        <a href="/" v-on:click="side_nav_open = false">HOME</a>
        <a href="/menu" v-on:click="side_nav_open = false">Menu</a>
        <a href="/#features" v-on:click="side_nav_open = false">About us</a>
        <a href="/#social" v-on:click="side_nav_open = false">Social Media</a>
        <a href="/#press" v-on:click="side_nav_open = false">Press</a>
        <a href="/review" v-on:click="side_nav_open = false">Review us</a>
      </div>
    </transition>

    <div id="main">
      <!-- <transition name="fade">
        <span
          class="btn-menu"
          v-on:click="side_nav_open = true"
          v-show="!this.side_nav_open"
        >
          <svg
            version="1.1"
            id="IconsRepoEditor"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 56 56"
            style="enable-background:new 0 0 56 56;"
            xml:space="preserve"
            width="100%"
            height="100%"
            fill="white"
            stroke="white"
            stroke-width="0"
          >
            <g id="IconsRepo_bgCarrier"></g>
            <path
              d="M28,0C12.561,0,0,12.561,0,28s12.561,28,28,28s28-12.561,28-28S43.439,0,28,0z M40,41H16c-1.104,0-2-0.896-2-2s0.896-2,2-2 h24c1.104,0,2,0.896,2,2S41.104,41,40,41z M40,30H16c-1.104,0-2-0.896-2-2s0.896-2,2-2h24c1.104,0,2,0.896,2,2S41.104,30,40,30z M40,19H16c-1.104,0-2-0.896-2-2s0.896-2,2-2h24c1.104,0,2,0.896,2,2S41.104,19,40,19z"
            ></path>
          </svg>
        </span>
      </transition> -->

      <button
        class="hamburger hamburger-arrow-left hamburger-cancel"
        v-bind:class="{ active: side_nav_open }"
        v-on:click="side_nav_open = !side_nav_open"
      >
        <span class="icon"></span>
      </button>

      <div v-on:click="side_nav_open = false">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {
      side_nav_open: false
    };
  }, //data
  methods: {
    // toggle_menu: function() {},
    // toggleNav: function() {
    //   //console.log(document.getElementById("mySidenav").style.width);
    //   this.side_nav_open = !this.side_nav_open;
    //   if (document.getElementById("mySidenav").style.width == "250px") {
    //     document.getElementById("mySidenav").style.width = "0px";
    //     //document.getElementById("btn-menu").style.display = "visible";
    //   } else {
    //     document.getElementById("mySidenav").style.width = "250px";
    //     ///document.getElementById("btn-menu").style.display = "none";
    //   }
    // }
  } // methods
};
</script>

<style lang="less">
@import "./assets/css/breakpoints.less";
//@import "@/assets/css/typography.scss";


@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

@btn_size: 30px;//5vh;


// .bkg-pink {
//   background-color: @setting_plaster;
// }

body,
p {
  font-size: 16px;
  line-height: 1.5;
}

h6 {
  text-transform: uppercase;
  color: grey !important;
  letter-spacing: 3px !important;
}

p,
html {
  color: @setting_plaster !important;
  //background-color: red !important;
}

html {
  box-sizing: border-box;
}

body {
  background-color: black;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  padding: 0;
  margin: 0;
  background-color: black;
}
body,
p {
  //font-family: "Arimo", sans-serif;
  font-family: "Open Sans", cursive;
  color: white;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Marcellus", serif;
  color: white;
}
h1 {
  color: @vardo;
  font-family: "Permanent Marker", cursive;
  font-size: 3em !important;
  margin-left: 2*@btn_size!important;
}

.content-wrapper {
  display: block;
  width: 100%;
  //margin-right: auto;
  //margin-left: auto;

  //padding-right: 25px;
  //padding-left: 25px;

  //background-color: rgb(0, 0, 0);
}

.content {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  border-bottom-style: none;

  //padding: 5vw 30px;

  padding: 5vw 0;
  flex: 1 1 0%;
}

.content-section {
  position: relative;
  display: block;
  width: 100%;

  //height: 100%;

  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.image-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;

  // width: 100%;

  height: auto;

  /*
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    */
}

/* layout */

.row-flex {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: stretch;

  div {
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    //flex: 1 1 100%;
    //flex-flow: row wrap;
    //align-items: stretch;
  }
}

/* animations */

.div-fade(@type, @delay, @length) {
  animation: fadein @length @type @delay forwards;
  -moz-animation: fadein @length @type @delay forwards; /* Firefox */
  -webkit-animation: fadein @length @type @delay forwards; /* Safari and Chrome */
  -o-animation: fadein @length @type @delay forwards; /* Opera */
}

// .fade-1s {
//   opacity: 0;
//   &.in-viewport {
//     @include div-fade(ease, 500ms, 1s);
//   }
// }

// .fade-2s {
//   opacity: 0;
//   &.in-viewport {
//     @include div-fade(ease, 1s, 1s);
//   }
// }

// .fade-3s {
//   opacity: 0;
//   &.in-viewport {
//     @include div-fade(ease, 1500ms, 1s);
//   }
// }

// .fade-4s {
//   opacity: 0;
//   &.in-viewport {
//     @include div-fade(ease, 2s, 1s);
//   }
// }

// .fade-5s {
//   opacity: 0;
//   &.in-viewport {
//     @include div-fade(ease, 2500ms, 1s);
//   }
// }

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// background-images

.contain-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.cover-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// OVERLAY image-text

.overlay {
  position: relative;
  overflow: hidden;
  width: 100%;

  padding: 0;
  margin: 0;

  image {
    display: block;
    width: 100%;
    height: auto;
  }
  .text {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .normal {
    width: 100%;
    transition: 1.2s ease;
    height: 100% !important;
    padding: 0;
    margin: 0;
    div {
      height: 100%;
    }
  }
  .hover {
    top: 0;
    height: 100% !important;
    width: 100%;
    opacity: 0;
    transition: 1.2s ease;

    padding: 0;
    margin: 0;
    div {
      height: 100%;
    }
  }
  &:hover .normal {
    opacity: 0;
  }
  &:hover .hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

// overlay grids
.card {
  color: white;
  border-radius: 5px;
  height: 100%;
  //font-weight: bold;
  //font-size: 3vw;
  //height: 40vh;
  //font-family: 'Permanent Marker', cursive;

  h1 {
    //font-family: 'Permanent Marker', cursive;
    color: @setting_plaster;
    font-size: 1.7em !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0.5em;
  }

  li,
  p {
    //font-size: 1em;
    color: @setting_plaster;
  }

  a {
    color: white;
  }

  @media (max-width: 750px) {
    h1 {
      //font-size:1em;
      margin: 0;
    }
    li,
    p {
      //font-size:1em;
    }
  }

  @media (max-width: 750px) {
    h1 {
      //font-size:1em;
    }
    li,
    p {
      //font-size:1em;
    }
  }
}

/*

SIDENAV
https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_sidenav

*/

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  & a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* menu hamburger */
/* https://w3bits.com/animated-hamburger-icons/ */

.hamburger {
  position: fixed;
  z-index: 2;
  top: @btn_size;
  left: @btn_size;
  //color: @setting_plaster;

  font-size: @btn_size;

  display: inline-block;

  width: 1em;
  height: 1em;
  padding: 0;

  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  vertical-align: middle;

  border: 0 none;
  background: transparent;
  &:hover {
    transform: scale(1.1);
  }

  &:before,
  &:after {
    content: "";
  }

  &:before,
  & .icon,
  &:after {
    display: block;

    width: 100%;
    height: 0.2em;
    margin: 0 0 0.2em;

    transition: transform 0.2s ease-in-out;

    border-radius: 0.05em;
    background: @setting_plaster; //#596c7d;
  }

  &.hamburger-arrow-left.active {
    transform: rotate(180deg);

    &:before {
      width: 0.6em;
      transform: translateX(0.4em) translateY(0.2em) rotate(45deg);
    }

    & .icon {
      border-radius: 0.1em 0.25em 0.25em 0.1em;
    }

    &:after {
      width: 0.6em;
      transform: translateX(0.4em) translateY(-0.2em) rotate(-45deg);
    }
  }
}
</style>
