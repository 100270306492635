<template>
  <div class="content" id="#contact">
    <div class="content-section fade-1s" v-in-viewport.once>
      <div class="content-grid">
        <div class="p1 overlay">
          <div class="normal">
            <div class="text">Location</div>
          </div>
          <div class="hover">
            <div class="text">juices</div>
          </div>
        </div>

        <div class="p2 overlay">
          <div class="normal">
            <div class="text">Timetable</div>
          </div>
          <div class="hover">
            <div class="text">juices</div>
          </div>
        </div>

        <div class="p3 overlay">
          <div class="normal">
            <div class="text">Social media</div>
          </div>
          <div class="hover">
            <div class="text">juices</div>
          </div>
        </div>

        <div class="p4 overlay">
          <div class="normal">
            <div class="text">Reviews</div>
          </div>
          <div class="hover">
            <div class="text">juices</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="content no-left-right">
    <div class="content-section fade-1s" v-in-viewport.once>
      <h1>Contact</h1>
        <div class="row-flex">

        <div class="md-6 sm-4 xs-12">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.473249637276!2d-0.143935184122037!3d50.82239746837492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875858177b48647%3A0x1b898d74616b886d!2sVa+Pensiero+Lounge!5e0!3m2!1sen!2suk!4v1559771561386!5m2!1sen!2suk" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
          </div>

        <div class="md-6 sm-4 xs-12 " style="text-align:left;">    
          <p>Come to meet us at 30 Ship Street, right in the centre of Brighton</p>      
            <p>email: <a href="mailto:info@vapensierolounge.com">info@vapensierolounge.com</a></p>
            <p>facebook: <a href="https://www.facebook.com/vapensierolounge/" class="social__item_link" target="_blank" rel="noopener">vapensierolounge</a></p>
            <p>instagram: <a href="https://www.instagram.com/vapensiero.lounge/" class="social__item_link" target="_blank" rel="noopener">vapensiero.lounge</a></p>
          </div>
      </div>          

    </div>
  </div>-->
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style scoped lang="less">
.content-grid {
  color: white;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  //max-height: 120vh;
  height: 50vw;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  // div {
  //   padding: 10px;
  // }

  .p1 {
    grid-row: 1;
    grid-column: 1;
    background-color: blue;
  }

  .p2 {
    grid-row: 2;
    grid-column: 1;
    background-color: red;
  }

  .p3 {
    grid-row: 1;
    grid-column: 2;
    background-color: green;
  }

  .p4 {
    grid-row: 2;
    grid-column: 2;
    background-color: yellow;
  }
}
</style>
