<template>
  <div class="home">
      <h1>Our Menu</h1>
    <div class="content-wrapper">

      <div class="content-wrapper">

        <a href="/menu/cafe" class="button_7">CAFE</a>

        <a href="/menu/restaurant" class="button_7">RESTAURANT</a>

      </div>
    </div>
  </div>
</template>

<script>
//import Footer from "@/components/Footer.vue";

export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";
@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

.content-wrapper{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

 p {
     color:darken(@setting_plaster,10%);
     margin:0;
 }

 ul {
   list-style-type: none;
   li{
     color:darken(@setting_plaster,10%);
   }
 }

.button_7 {
  width:20em;
    background-color:@vardo;
    display:inline-block;
    padding:4em ;
    margin:0 0.3em 0.3em 0;
    border-radius:0.2em; 
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#FFFFFF;
    //background-color:#3369ff;
    box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
    text-align:center;
    position:relative;
}
.button_7:active{
  box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}
@media all and (max-width:30em){
  .button_7{
    display:block;
    margin:0.4em auto;
  }
}
</style>
