<template>
  <div class="home">
    <Hero />
    <div class="content-wrapper">
      <!-- <About /> -->
      <ProductTypes />
      <Features />
      <Facebook />
      <Press />>
      <!-- <Contact /> -->
    </div>
    
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
// import About from "@/components/About.vue";
import Facebook from "@/components/Facebook.vue";
import ProductTypes from "@/components/ProductTypes.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
//import Various from "@/components/Various.vue";
import Features from "@/components/Features.vue";
import Press from "@/components/Press.vue";

export default {
  name: "home",
  components: {
    Hero,
    // About,
    Facebook,
    ProductTypes,
    Contact,
    Footer,
    Press,
    //Various,
    Features
  }
  //     created:function(){
  //   var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
  //   i.src = "/curator/published/d7681573-02ab-48b4-9342-916de25f.js";
  //   e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  // }, // created
};
</script>
