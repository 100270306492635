var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content no-left-right",attrs:{"id":"features"}},[_c('h1',[_vm._v("About us")]),_c('div',{staticClass:"wrapper fade-1s"},[_c('div',{staticClass:"location overlay card",attrs:{"id":"c_location"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/map.png')})`})})]),_vm._m(0)]),_c('div',{staticClass:"seating overlay card",attrs:{"id":"c_seating"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/IMG_20190710_113257.jpg')})`})})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('P',[_vm._v("Enjoy our warm and cosy internal room")])],1)])]),_vm._m(1),_c('div',{staticClass:"garden overlay card",attrs:{"id":"c_garden"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/IMG-20200805-WA0002.jpeg')})`})})]),_vm._m(2)]),_c('div',{staticClass:"social overlay card",attrs:{"id":"c_social"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/instagram-facebook.png')})`})})]),_vm._m(3)]),_c('div',{staticClass:"hire overlay card",attrs:{"id":"c_hire"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/hire.png')})`})})]),_vm._m(4)]),_c('div',{staticClass:"office overlay card",attrs:{"id":"c_office"}},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({'background-image': `url(${require('@/assets/400/office.jpg')})`})})]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v("We are right in the centre of Brighton")]),_c('p',[_c('a',{attrs:{"href":"https://goo.gl/maps/64rZV8bPU6F2vVFc8","target":"_blank"}},[_vm._v("30 Ship Street")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timetable card",attrs:{"id":"c_timetable"}},[_c('p',[_vm._v("We are open every day")]),_c('ul',[_c('li',[_vm._v("Monday: 08:00 - 22:00")]),_c('li',[_vm._v("Tuesday: 08:00 - 22:00")]),_c('li',[_vm._v("Wednesday: 08:00 - 22:00")]),_c('li',[_vm._v("Thursday: 08:00 - 22:00")]),_c('li',[_vm._v("Friday: 08:00 - 22:00")]),_c('li',[_vm._v("Saturday: 08:00 - 22:00")]),_c('li',[_vm._v("Sunday: 08:00 - 22:00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v("Discover our hidden garden")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('p'),_c('p',[_c('a',{staticClass:"social__item_link",attrs:{"id":"l_facebook","href":"https://www.facebook.com/vapensierolounge/","target":"_blank","rel":"noopener"}},[_vm._v("Facebook")]),_vm._v(" or "),_c('a',{staticClass:"social__item_link",attrs:{"id":"l_instagram","href":"https://www.instagram.com/vapensierolounge/","target":"_blank","rel":"noopener"}},[_vm._v("Instagram")])]),_c('p',[_vm._v("@vapensierolounge")]),_c('p',[_vm._v("Don't forget to "),_c('a',{attrs:{"href":"/review"}},[_vm._v("like and follow us")]),_vm._v("!!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v("Venue Available for private hire.")]),_c('p',[_vm._v("INTERESTED?")]),_c('p',[_vm._v("Wether it is a private party, business meeting, birthday or hen party ...")]),_c('p',[_vm._v("just email bookings@vapensierolounge.com. For a small fee we provide also food and beverages of your choice.")]),_c('p',[_vm._v("... and our internet is REALLY fast.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v("We bring lunch to your office.")]),_c('p',[_vm._v("If you work in the area you can pre-order your food at a discounted price. ")]),_c('p',[_vm._v("We deliver for free orders above £20.")])])])
}]

export { render, staticRenderFns }