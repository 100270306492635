<template>
  <div class="logs">    

 <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li v-if="!isAuthenticated">
          <a href="#" @click.prevent="login">Login</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="#" @click.prevent="logout">Log out</a>
        </li>
      </ul>
      
      <h1>Va Pensiero Lounge logs</h1>


</div>
</template>

<script>

export default {
  name: 'logs',
  components: {
  },
  data: function(){
    return{
    isAuthenticated: false
    }
  },
   async created() {
    try {
      await this.$auth.renewTokens();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    login() {
      this.$auth.login();
    },
    logout() {
      this.$auth.logOut();
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn;
      this.profile = data.profile;
    }
  }
}
</script>

<style lang="less" scoped>

body{
  background-color:white;
}
h2,h3{
  color:green;
}

.logs{
    display:flex;
    flex-direction: column;
    width:100%;
    //flex: 1 1 50%;
  .row{
      //flex: 1 1 33%;
      display: flex;
      flex-direction: row;
      align-items:center;
      justify-content:center;
      a{
        //width:15em;
        margin-left:1em;
        margin-right:1em;
        padding-left:2em;
        padding-right:2em;
        //flex: 1 1 25%;
        height:3em;
        line-height:3em;
        background-color:seagreen;
        color:pink;
      }
  }
.col{}

}
</style>
