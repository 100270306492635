<template>
  <div class="content no-left-right " id="features">
      <h1>About us</h1>

  <!-- <div class="wrapper fade-1s" v-in-viewport.once> -->
  <div class="wrapper fade-1s" >
        <div class="location overlay card" id="c_location">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/map.png')})`}"></div>
          </div>
          <div class="hover">
            <div class="text">
              <p>We are right in the centre of Brighton</p>
              <p><a href="https://goo.gl/maps/64rZV8bPU6F2vVFc8"  target=_blank>30 Ship Street</a></p>
              <!-- <p>You can reach us on 07828 796418 (also WhatsApp)</p> -->
            </div>
          </div>
        </div>

        <div class="seating overlay card" id="c_seating">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/IMG_20190710_113257.jpg')})`}"></div>
          </div>
          <div class="hover">
            <div class="text"><P>Enjoy our warm and cosy internal room</P></div>
          </div>
        </div>

        <div class="timetable card" id="c_timetable">
          <p>We are open every day</p>
          <ul>
          <li>Monday: 08:00 - 22:00</li>
          <li>Tuesday: 08:00 - 22:00</li>
          <li>Wednesday: 08:00 - 22:00</li>
          <li>Thursday: 08:00 - 22:00</li>
          <li>Friday: 08:00 - 22:00</li>
          <li>Saturday: 08:00 - 22:00</li>
          <li>Sunday: 08:00 - 22:00</li>
          </ul>
        </div>

        <div class="garden overlay card" id="c_garden">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/IMG-20200805-WA0002.jpeg')})`}"></div>
          </div>
          <div class="hover">
            <div class="text"><p>Discover our hidden garden</p></div>
          </div>
        </div>

        <div class="social overlay card" id="c_social">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/instagram-facebook.png')})`}"></div>
          </div>
          <div class="hover">
            <div class="text">
              <p></p>
              <p>
                <a id="l_facebook" href="https://www.facebook.com/vapensierolounge/" class="social__item_link" target="_blank" rel="noopener">Facebook</a>
                or
                <a id="l_instagram" href="https://www.instagram.com/vapensierolounge/" class="social__item_link" target="_blank" rel="noopener">Instagram</a>
              </p>
              <p>@vapensierolounge</p>
              <p>Don't forget to <a href="/review">like and follow us</a>!!</p>
            </div>
          </div>
        </div>

        <div class="hire overlay card" id="c_hire">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/hire.png')})`}"></div>
          </div>
          <div class="hover">
            <div class="text">
        <p>Venue Available for private hire.</p>
        <p>INTERESTED?</p>
        <p>Wether it is a private party, business meeting, birthday or hen party ...</p>
        <p>just email bookings@vapensierolounge.com. For a small fee we provide also food and beverages of your choice.</p>
        <p>... and our internet is REALLY fast.</p>
            </div>
          </div>
        </div>

        <div class="office overlay card" id="c_office">
          <div class="normal">
            <div class="cover-image" :style="{'background-image': `url(${require('@/assets/400/office.jpg')})`}"></div>
          </div>
          <div class="hover">
            <div class="text">
      <p>We bring lunch to your office.</p>
      <p>If you work in the area you can pre-order your food at a discounted price. </p>
      <p>We deliver for free orders above £20.</p>
            </div>
          </div>
        </div>


    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Features"
};
</script>

<style scoped lang="less">

.wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-template-rows: repeat(4, minmax(250px, 1fr));
  
  .location{
    grid-row: 1 ;
    grid-column:1;
  }
  
  .garden{  
    grid-row: 2 / span 2;
    grid-column:1;
  }

  .seating {
    grid-row: 1 / span 2;
    grid-column:2;
  }

  .social {
    grid-row: 3 ;
    grid-column:2;
  }

  .timetable{
    grid-row: 1 ;
    grid-column:3;
  }

  .hire{  
    grid-row: 2 / span 2;
    grid-column:3;
  }
  .office{  
    grid-row: 4;
    grid-column:1 / span 3;
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-template-rows: repeat(6, minmax(300px, 1fr));

    .location{
      grid-row: 1 ;
      grid-column: 1 ;
    }
    .garden{  
      grid-row: 1 / span 2;
      grid-column: 2 ;
    }

    .seating {
      grid-row:  2 / span 2;
      grid-column:1 ;
    }
    .social {
      grid-row: 4 /span 2 ;
      grid-column:1;
    }

    .timetable{
      grid-row: 5 ;
      grid-column:2 ;
    }
    .hire{  
      grid-row: 3 / span 2;
      grid-column:2 ;
    }
      .office{  
    grid-row: 6;
    grid-column:1 / span 2;
  }

  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    grid-template-rows: repeat(7, minmax(300px, 1fr));
    grid-column-gap: 0px;

    .location{
      grid-row: 1 ;
      grid-column: 1 ;

    }
    .garden{  
      grid-row: 2  ;
      grid-column: 1 ;
    }

    .seating {
      grid-row:  3 ;
      grid-column: 1 ;
    }
    .social {
      grid-row: 4 ;
      grid-column:1 ;
    }

    .timetable{
      grid-row: 5 ;
      grid-column: 1 ;
    }
    .hire{  
      grid-row: 6 ;
      grid-column:1 ;
    }
      .office{  
    grid-row: 7;
    grid-column:1 ;
  }


  }


}


// .content-grid {
//   color: white;
//   display: grid;
//   grid-gap: 20px;
//   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
//   //grid-template-rows: auto;
//   grid-template-rows: repeat(3, 1fr);
//   //grid-column-gap: 20px;
//   //grid-row-gap: 20px;
//   grid-auto-flow: row;
//   //max-height: 120vh;
//   //height: 50vw;
//   //max-width: 100%;
//   //width: 100%;
//   margin: 0;
//   padding: 0;

//   .p1 {
//     grid-column: auto;
//     grid-row: auto/span 2;
//     // grid-row: 1 / span 2;
//     // grid-column: 1;
//     background-color: red;
//   }

//   .p2 {
//     grid-column: auto;
//     grid-row: auto;

//     // grid-row: 1;
//     // grid-column: 2;
//     background-color: green;
//   }

//   .p3 {
//     // grid-row: 1;
//     // grid-column: 3;
//     background-color: blue;
//   }

//   .p4 {
//     // grid-row: 2;
//     // grid-column: 2 / span 2;
//     //grid-column: span 2;
//     background-color: red;
//   }

//   .p5 {
//     // grid-row: 3;
//     // grid-column: 1 / span 2;
//     //grid-column: span 2;
//     background-color: green;
//   }

//   .p6 {
//     // grid-row: 3;
//     // grid-column: 3;
//     background-color: blue;
//   }
// }
</style>
