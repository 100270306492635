<template>
  <div class="hero flex-center">
    <div class="hero-message">
      <div class="logo contain-image fade-2s" ></div>
      <p class="fade-4s" >independent café</p>
      <p class="fade-4s" >simple good food for good people</p>
      <!-- <div class="logo contain-image fade-2s" v-in-viewport.once></div>
      <p class="fade-4s" v-in-viewport.once>independent café</p>
      <p class="fade-4s" v-in-viewport.once>simple good food for good people</p> -->
    </div>
  </div>

  <!-- <div
    class="hero"
    :style="{
      'background-image': `linear-gradient(rgba(0, 0, 0, 0.4), transparent 50%), 
    url(${require('@/assets/1200/DSC_8270-faded.jpg')})`
    }"
  >
    <div
      class="content center fade-2s logo contain-image"
      v-in-viewport.once
      :style="{
        'background-image': `linear-gradient(rgba(0, 0, 0, 0.4), transparent 50%), 
    url(${require('@/assets/svg/va_pensiero_logo_pink.svg')})`
      }"
    ></div>
  </div> -->
</template>

<script>
export default {
  name: "Hero"
};
</script>

<style scoped lang="less">
.logo {
  width: auto;
  height: 20vh;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;

  content: "";
  display: block;
  background-image: url("~@/assets/svg/va_pensiero_logo_pink.svg");
  //   @media (max-width: 360px) {
  //   max-width: 90%;
  // }
}
@media (max-width: 749px) {
  .logo {
    //width: 50vw;
  }
}

@media (max-width: 499px) {
  .logo {
    //width: 65vw;
  }
}
/* 
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
} */

.hero {
  max-height: 100vh;
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.hero::before {
  background-image: url("~@/assets/1200/DSC_8270-faded.jpg");
  background-size: cover;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.4;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.hero-message {
  min-width: 100%;
  min-height: 12em;
  position: relative;
  p {
    color: #e0c9bc;
    text-shadow: #343a40 2px 2px;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    font-size: 2em;
    @media (max-width: 499px) {
      font-size: 1.5em;
    }
    @media (max-width: 360px) {
      font-size: 1.2em;
    }
  }
}
.hero-message::before {
  content: "";
  display: block;
  position: absolute;
  margin-left: 0;
  min-width: 100%;
  min-height: 12em;
  z-index: -1;
  /*opacity: 0.4;
  background-color: #343a40;*/
}
.hero::after {
  background-color: #563d7c;
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.4;
}

/* div.hero {
  max-height: 100vh;
  height: 100vh;
  max-width: 100%;
  width: 100%;

  background-size: auto, cover;
  background-position: 0px 0px, 50% 50%;
  background-repeat: repeat, no-repeat;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  box-shadow: inset 0 0 30em 4em #000;
} */

.content.center {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
h1 {
  color: white;
  font-size: 5vw;
  line-height: 1.1em;
  font-weight: 400;
}
</style>
