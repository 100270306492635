<template>
  <section>
    <div class="footer bkg-pink">
      <!-- <div id="TA_cdsscrollingravenarrow709" class="TA_cdsscrollingravenarrow">
        <ul id="FzmcXHZ" class="TA_links ogPtDq">
          <li id="tfUNFsT1Wf" class="IhibcsJtt">
            <a target="_blank" href="https://www.tripadvisor.co.uk/"
              ><img
                src="https://static.tacdn.com/img2/t4b/Stacked_TA_logo.png"
                alt="TripAdvisor"
                class="widEXCIMG"
                id="CDSWIDEXCLOGO"
            /></a>
          </li>
        </ul>
      </div> -->
      &nbsp;
      <div class="social__item">
        @2022 Va Pensiero Lounge - info@vapensierolounge.com
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="less">
.footer {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 1em;

  .social__item {
    display: inline;
    flex: 1 0 auto;
  }
  .social__item_icon {
    height: 1em;
    width: 1em;
  }
}

// .social{
//   display:flex;
//   flex-wrap: wrap;
//    justify-content: center;
//   .social__item{
//     flex-shrink:1;
//     .social__item_icon{
//       height:25px;
//     }
//   }
// }
</style>
