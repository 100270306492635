<template>
  <div class="content no-left-right" id="press">
    <div class="content-section fade-1s" >
    <!-- <div class="content-section fade-1s" v-in-viewport.once> -->
      <h1>What do they say about us ?</h1>

      <div class="press">
        <!-- <a
          href="https://www.bigdish.com/blog/cuisines/4-italian-restaurants-you-cant-miss-in-brighton/"
          target="_blank"
          class="logo"
        > -->
        <div class="logo">
          <img :src="require('@/assets/200/bigdish.png')"  />
        </div>          

          <p class="motto">
            Their motto is “simple food done right”, and this is how it should
            be everywhere, really. Va Pensiero Lounge offers a variety of
            freshly baked foods ideal for aperitivo or breakfast, few steps away
            from the spectacular views from the Brighton Palace Pier. But it’s
            not all about panini and cornetti: you should also try their variety
            of pasta, pizza and risotto – made on the spot. Now, coffee anyone?
          </p>
      </div>
      <div class="press inverted">
        <!-- <a
          href="https://www.facebook.com/mattiarussel.pantalone/posts/2517767978273064"
          target="_blank"
          class="logo"
        > -->
        <div class="logo">
          <img :src="require('@/assets/200/facebook-icon.svg')" />
          </div>
        </a>
          <p class="motto">
            Best coffee in town and cosy atmosphere, highly recommended. We had
            a cappuccino and the charcoal croissant filled with pistachio cream,
            delicious! We'll come back for lunch those homemade sandwiches look
            yummy 😋
          </p>
      </div>

      <div class="press">
        <!-- <a
          href="https://www.tripadvisor.co.uk/ShowUserReviews-g186273-d17729751-r727815373-Va_Pensiero_Lounge-Brighton_East_Sussex_England.html"
          target="_blank"
          class="logo"
        > -->
        <div class="logo">
        <img :src="require('@/assets/200/tripadvisor.svg')"  />
        </div>
        <p class="motto">
          Lovely coffee and cakes! My husband and I went to this place with our
          3 month old baby when spending a weekend in Brighton. Really nice and
          cosy place. The owner was really welcoming and patiently helped us in
          settling upstairs with the carrycot. We had a lovely afternoon break
          with a delicious chocolate roll and we came back the next morning for
          a real italian coffee and a charcoal cornetto.... definitely worth a
          visit!
        </p>
      </div>

      <div class="press">
        <link href="https://awards.infcdn.net/circle_v2.css" rel="stylesheet"/>
        <div id="circle_v2" onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.sq_center').href);return 0;}"> <div class="arc-heading "> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="160px" height="160px" viewBox="0 0 160 160"> <defs> <path id="heading-arc" d="M 30 80 a 50 50 0 1 1 100 0"></path> </defs> <text class="arc-heading__heading " fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#heading-arc">Recommended</textPath> </text> </svg> </div> <div class="sq_year">2021</div> <a href="https://restaurantguru.com/Va-Pensiero-Lounge-Brighton" class="sq_center f14" target="_blank">Va Pensiero Lounge</a> <div class="arc-heading arc-heading__bottom"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120px" height="120px" viewBox="0 0 120 120"> <defs> <path id="subheading-arc" d="M 12 60 a 48 48 0 0 0 96 0"></path> </defs> <text class="arc-heading__subheading" fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#subheading-arc"><a href="https://restaurantguru.com" target="_blank">Restaurant Guru</a></textPath> </text> </svg> </div></div>
        <div id="circle_v2" onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.sq_center').href);return 0;}"> <div class="arc-heading "> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="160px" height="160px" viewBox="0 0 160 160"> <defs> <path id="heading-arc" d="M 30 80 a 50 50 0 1 1 100 0"></path> </defs> <text class="arc-heading__heading " fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#heading-arc">Recommended</textPath> </text> </svg> </div> <div class="sq_year">2022</div> <a href="https://restaurantguru.com/Va-Pensiero-Lounge-Brighton" class="sq_center f8" target="_blank">Va Pensiero Lounge cafe restaurant</a> <div class="arc-heading arc-heading__bottom"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120px" height="120px" viewBox="0 0 120 120"> <defs> <path id="subheading-arc" d="M 12 60 a 48 48 0 0 0 96 0"></path> </defs> <text class="arc-heading__subheading" fill="#000" text-anchor="middle"> <textPath startOffset="50%" xlink:href="#subheading-arc"><a href="https://restaurantguru.com" target="_blank">Restaurant Guru</a></textPath> </text> </svg> </div></div>
      </div>



    </div>
  </div>
</template>

<script>
export default {
  name: "Press"
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";

.content-section{
  display:flex;
  flex-direction:column;
  align-items:center;
}

.press {
  flex: 0 1 100%;
  padding:1em;
  //margin-bottom:2em;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.motto {
    padding:1em;
}

.logo {
  max-width: 25%;
}

    img{
      max-width:100%;
      max-height:100%;
    }

@media (min-width: 360px) {
  .press{
    display:flex;
    flex-direction:row;
    &.inverted{
    flex-direction:row-reverse;
    }

    //max-width:95%;
    padding:1em;
    justify-content:space-between;
  .logo{
    display:flex;
    flex:1 1 20%;
    height:auto;
  }
  .motto{
    display:flex;
    flex:1 1 80%;
  }
  }
}

@media (min-width: 768px) {
  .press{
  .logo{
    flex:1 1 10%;
  }
  .motto{
    flex:1 1 90%;
  }
  }
}
@media (min-width: 960px) {
  .press{
  .logo{
    flex:1 1 5%;
  }
  .motto{
    flex:1 1 95%;
  }
  }
}

// .press {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;

//   border: none;
//   font-family: Georgia, "Times New Roman", Times, serif;
//   margin-bottom: 30px;
//   quotes: "\201C""\201D""\2018""\2019";

//   &.inverted {
//     flex-direction: row-reverse;
//   }

//   .logo {
//     flex: 1 1 10%;
//     background-position: center;
//     background-size: contain;
//     background-repeat: no-repeat;
//     margin: 1em;
//     img {
//       max-width: 90%;
//     }
//   }

//   .motto {
//     flex: 1 1 90%;
//     display: flex;
//     flex-direction: row;

//     &:before {
//       content: open-quote;
//       font-weight: bold;
//       font-size: 100px;
//       color: #889c0b;
//     }
//     &:after {
//       content: close-quote;
//       font-weight: bold;
//       font-size: 100px;
//       color: #889c0b;
//     }
//   }
// }

// @media (max-width: 1024px) {
//   .press {
//     .logo {
//       flex-basis: 20%;
//     }
//     .motto {
//       flex-basis: 80%;
//     }
//   }
// }

// @media (max-width: 360px) {
//   .press {
//     display: block;
//     //justify-content: start;
//     //align-items: left;
//     flex-direction: column;

//     &.inverted {
//       flex-direction: column;
//     }
//     //overflow: visible;
//     //display: block;
//     .logo {
//       max-width: 25%;
//       height: auto;
//       display: block;
//       //flex: 0 0 25%;
//       //float: left;
//       //display: inline-block;
//     }
//     .motto {
//       display: block;
//       //flex: 0 0 75%;
//       //float: left;
//       //display: inline-block;
//       //overflow: visible;
//       &:before {
//         content: "";
//       }
//       &:after {
//         content: "";
//       }
//     }
//   }
// }

// .content.no-left-right {
//   padding-right: 0px;
//   padding-left: 0px;
// }

// // .wrap{
// //   display:flex;
// //   width:100%;
// //   flex-flow: row wrap;
// //   align-items: center;
// //   justify-content:space-evenly;

// //   div {
// //     flex: 0 0 100%;

// //     flex-flow: row wrap;
// //     align-items: stretch;

// //     margin-bottom:1em;

// //     @media (min-width:$SM) {
// //       flex-basis:47%;
// //     }

// //     @media (min-width:$MD) {
// //       flex-basis:31%;
// //       &.margin-3-col {
// //         margin-top:3em!important;
// //       }
// //     }
// //   }
// //}

// .image-wrapper {
//   min-height: 300px;
// }
</style>
