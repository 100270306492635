<template>
  <div class="home">
    <div class="content-wrapper">
      <h1>Please leave us a review</h1>

<p> We would be very grateful if you left us a review. </p>
<p>the staff of Va Pensiero Lounge</p>
<br/><br/>

<a href="https://g.page/va-pensiero-lounge/review?mt" target = "_blank">
<div class="box">
<div class="contain-image" :style="{'background-image': `url(${require('@/assets/google-logo.svg')})`}"></div>
</div>
</a>


<a href="https://www.tripadvisor.co.uk/UserReviewEdit-g186273-d17729751-Va_Pensiero_Lounge-Brighton_East_Sussex_England.html" target = "_blank">
<div class="box">
<div class="contain-image" :style="{'background-image': `url(${require('@/assets/tripadvisor-logo-vector-01.png')})`}"></div>
</div>
</a>


<a href="https://www.instagram.com/vapensierolounge/" target = "_blank">
<div class="box">
<div class="contain-image" :style="{'background-image': `url(${require('@/assets/new-instagram-logo-seeklogo.net.png')})`}"></div>
</div>
</a>


<a href="https://www.facebook.com/vapensierolounge/" target = "_blank">
<div class="box">
<div class="contain-image" :style="{'background-image': `url(${require('@/assets/find-us-on-facebook-badge.png')})`}"></div>
</div>
</a>


<!--
<p>Like us on facebook</p>
      <div id="fb-root"></div>
        <div
          class="fb-like"
          data-href="https://www.facebook.com/vapensierolounge"
          data-layout="standard"
          data-action="like"
          data-show-faces="true"
        ></div>
-->

    </div>  

</div> 

</template>

<script>
export default {
  name: "Social",
  components: {},
  data() {
    return {};
  },
  created() {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0"
    );
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", true);
    script.setAttribute("defer", true);
    document.head.appendChild(script);

  } // created
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";
@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

.content-wrapper{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box{
  margin:.5em;
  padding:1em;
  background-color:@setting_plaster;
  width:200px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */

  div{
    height:50px;
  }
}

html {
  //background-color: $setting_plaster;
}
h1,
h2,
h3,
li,
p {
  //color: $vardo !important;
}
ul {
  //list-style-type: none;
}
a{
  color:white;
}
</style>
