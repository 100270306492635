import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Logs from "./views/Logs.vue";
import Menu from "./views/Menu.vue";
import Menu_cafe from "./views/Menu-cafe.vue";
import Menu_restaurant from "./views/Menu-restaurant.vue";
import Review from "./views/Review.vue";

import Callback from "./components/Callback";
import Profile from "./views/Profile.vue";

import auth from "./auth/authService";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function() {
        return import(/* webpackChunkName: "about" */ "./views/About.vue");
      }
    },
    {
      path: "/logs/:id?",
      name: "logs",
      component: Logs
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/menu/cafe",
      name: "menu-cafe",
      component: Menu_cafe
    },
    {
      path: "/menu/restaurant",
      name: "menu-restaurant",
      component: Menu_restaurant
    },
    {
      path: "/review",
      name: "review",
      component: Review
    },
    {
      path: "/login",
      name: "login",
      beforeEnter: (to, from, next) => {
        if (auth.isAuthenticated()) {
          return next();
        }
        auth.login({});
      }
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        if (auth.isAuthenticated() == false) {
          //return next();
        }
        auth.logOut({
          target: "/"
        });
      }
    },
    {
      path: "/callback",
      name: "callback",
      component: Callback
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: (to, from, next) => {
        if (auth.isAuthenticated()) {
          return next();
        }

        auth.login({
          target: to.path
        });
      }
    }
    // {
    //   path: "/forms/daily",
    //   beforeEnter() {
    //     location.href =
    //       "https://docs.google.com/forms/d/e/1FAIpQLSfkWj_jRS3jTKWBCWlcJDyUv9lc-KR-coayPg23aAhLDgw95A/viewform?usp=sf_link";
    //   }
    // }
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});
