<template>
  <div class="content no-left-right" id="products">
    <h1>Our menu</h1>

    <div class="wrapper fade-1s" >
    <!-- <div class="wrapper fade-1s" v-in-viewport.once> -->

      <div class="soup overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/soup.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#soup')">
            <h1>Soups</h1>
            <p>Healthy Mediteranean veggies</p>
          </div>
        </div>
      </div>

      <div class="juices overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/juices.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#drinks')">
            <h1>Juices</h1>
            <p>Made with fresh fruit</p>
          </div>
        </div>
      </div>

      <div class="sweet overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/IMG_20190914_161513.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#sweet')">
            <h1>Sweets</h1>
            <ul>
              <li>Croissants</li>
              <li>Cakes</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="savoury overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/DSC_8313.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#savoury')">
            <h1>Savoury</h1>
            <ul>
              <li>Pasta to go</li>
              <li>Panini</li>
              <li>Pizza - Bruschetta</li>
              <li>Salad</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="breakfast overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/breakfast.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#breakfast')">
            <h1>Breakfast</h1>
            <p>English breakfast with an italian twist</p>
            <p>Available also for vegans</p>
          </div>
        </div>
      </div>
      <div class="coffee overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/coffee.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#coffee')">
            <h1>Coffee</h1>
            <p>Directly from Naples</p>
          </div>
        </div>
      </div>
      <div class="aperitivo overlay card">
        <div class="normal">
          <div
            class="cover-image"
            :style="{
              'background-image': `url(${require('@/assets/400/aperitivo.jpg')})`
            }"
          ></div>
        </div>
        <div class="hover">
          <div class="text" v-on:click="$router.push('menu#aperitivo')">
            <h1>Aperitivo</h1>
            <p>Free food with your drink. Exaclty like in Milan.</p>
          </div>
        </div>
      </div>
    </div>
    <a class="button" href="/menu" target="_blank">Full menu</a>
  </div>
</template>

<script>
export default {
  name: "ProductTypes",
  data: function() {
    return {};
  }, // data
  methods: {}, // methods
  mounted: function() {} // mounted
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";

@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

.button {
  background-color: @vardo;
  padding: 1em;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  color: @setting_plaster;
  text-decoration: none;
  font-weight: bold;
  width: auto;
}

.wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-template-rows: repeat(3, minmax(250px, 1fr));

  .juices {
    grid-row: 1;
    grid-column: 1;
  }

  .soup {
    grid-row: 1;
    grid-column: 2;
  }
  .aperitivo {
    grid-row: 3;
    grid-column: 1;
  }

  .savoury {
    grid-row: 2;
    grid-column: 1 / span 2;
  }

  .breakfast {
    grid-row: 2;
    grid-column: 3;
  }

  .sweet {
    grid-row: 3;
    grid-column: 2 / span 3;
  }

  .coffee {
    grid-row: 1;
    grid-column: 3;
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-template-rows: repeat(3, minmax(200px, 1fr));

    .juices {
      grid-row: 1;
      grid-column: 1;
    }

    .aperitivo {
      grid-row: 1;
      grid-column: 2;
    }

    .savoury {
      grid-row: 2;
      grid-column: 1;
    }

    .sweet {
      grid-row: 2;
      grid-column: 2;
    }

    .breakfast {
      grid-row: 3;
      grid-column: 1;
    }

    .coffee {
      grid-row: 3;
      grid-column: 2;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    grid-template-rows: repeat(6, minmax(200px, 1fr));
    grid-column-gap: 0px;

    .juices {
      grid-row: 1;
      grid-column: 1;
    }

    .aperitivo {
      grid-row: 2;
      grid-column: 1;
    }

    .savoury {
      grid-row: 3;
      grid-column: 1;
    }

    .sweet {
      grid-row: 4;
      grid-column: 1;
    }

    .breakfast {
      grid-row: 5;
      grid-column: 1;
    }

    .coffee {
      grid-row: 6;
      grid-column: 1;
    }
  }
}

.content-grid {
  color: white;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  //max-height: 120vh;
  height: 100vw;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .p1 {
    grid-row: 1;
    grid-column: 1;
  }

  .p2 {
    grid-row: 1;
    grid-column: 2 / span 2;
  }

  .p3 {
    grid-row: 2 / span 2;
    grid-column: 1;
  }

  .p4 {
    grid-row: 2;
    grid-column: 2;
  }

  .p5 {
    grid-row: 3;
    grid-column: 2;
  }

  .p6 {
    grid-row: 2 / span 2;
    grid-column: 3;
  }
}

@media (max-width: 750px) {
  .content-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 150vw;

    .p1 {
      grid-row: 1;
      grid-column: 1;
    }

    .p2 {
      grid-row: 1;
      grid-column: 2;
    }

    .p3 {
      grid-row: 2;
      grid-column: 1;
    }

    .p4 {
      grid-row: 2;
      grid-column: 2;
    }

    .p5 {
      grid-row: 3;
      grid-column: 1;
    }

    .p6 {
      grid-row: 3;
      grid-column: 2;
    }
  }
}

@media (max-width: 500px) {
  .content-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 600vw;

    .p1 {
      grid-row: 1;
      grid-column: 1;
    }

    .p2 {
      grid-row: 2;
      grid-column: 1;
    }

    .p3 {
      grid-row: 3;
      grid-column: 1;
    }

    .p4 {
      grid-row: 4;
      grid-column: 1;
    }

    .p5 {
      grid-row: 5;
      grid-column: 1;
    }

    .p6 {
      grid-row: 6;
      grid-column: 1;
    }
  }
}

// .image-wrapper{
//   display:flex;
//    flex-flow: row wrap;
// //   align-items: stretch;
//     //min-height:300px;
//     //height:300px;

// img{
//   width:100%;
//   padding:1em;
//     flex: 1 1 100%;

// }

//   .image{
//     flex: 1 1 100%;
// //    flex-flow: row wrap;
// //    align-items: stretch;

//     @media (min-width: $MD) {
//       flex-basis: 50%;
//     }

//   }
//}

// .wrap {
//   display: flex;
//   width: 100%;
//   flex-flow: row wrap;
//   align-items: stretch;

//   div {
//     flex: 1 1 100%;
//     flex-flow: row wrap;
//     align-items: stretch;

//     @media (min-width: $MD) {
//       flex-basis: 50%;
//     }
//   }
// }

// .tabs-link {
//   color: white;
//   padding-top: 3em;
//   padding-bottom: 3em;
//   &.active {
//     color: #ffce50;
//   }
// }
// .tabs-div {
//   display: none;
//   color: white;
//   flex:1 1 100%;
//   &.active {
//     display: inherit;
//     color:white;
//   }
// }

// .image-wrapper{
//   min-height: 300px;
// }
</style>
