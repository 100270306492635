<template>
  <div class="home">
    <div class="content-wrapper">
      <h1>Our RESTAURANT Menu</h1>
      <a href="/menu/cafe">Switch to CAFE menu</a>

      <div class="content-wrapper">
        <div>
          <h2>Specials</h2>
          <p>Grilled steak</p>
          <p>Seabass</p>
          <p>Tuna with Prosecco</p>
          <p>Chicken Mushrooms</p>
          <p>Mixed Vegetables Grill</p>
        </div>

        <div>
          <h2>Burgers</h2>
          <p>Italian: Beef, Nduja, Fontina, Pancetta, Red Onion, Tomato, Peppers, Salad, Potato Chips</p>
          <p>Spanish: Beef, Chorizo, Red Onion, Tomato, Peppers, Salad, Potato Chips</p>
          <p>Greek: Beef/Pork, Cucumber, Feta, Aubergine, Red Onion, Tomato, Peppers, Salad, Potato Chips</p>
          <p>Arabic: Chicken (halal), Harissa, Arabic Cheese, Red Onion, Tomato, Peppers, Salad, Potato Chips</p>
          <p>Vegan: Falafel, Houmous, Vegan Cheese, Red Onion, Tomato, Peppers, Salad, Potato Chips</p>
        </div>

        <div>
          <h2>Pasta</h2>
          <h3>Pasta (meat)</h3>
          <p>Penne alla VODKA: Vodka, Parma Ham, Butter, Creme Parmigiano </p>
          <p>Spaghetti Carbonara: Pancetta, Eggs, Parmigiano, White Wine</p>
          <p>Penne Amatriciana: Tomato, Pancetta, Chilly, Onion, White Wine, Parmigiano</p>
          <p>Spaghetti Chicken: Chicken (Halal), Mushrooms, Cheese, Creme, Olive Oil</p>
          <p>Tagliatelle Ragu': Beef, Carrot, Onion, Celery, Olive oil</p>
          <p>Linguine Meatball: Beef, Tomato, Carrot, Onion, Celery, Tomato, Cream</p>
          
          <h3>Pasta (vegetarian)</h3>
          <p>Penne Arrabbiata: Tomato, Chilly</p>
          <p>Penne Pesto: Basil pesto, Olive oil</p>
          <p>Spaghetti Vegetables: Courgettes, Aubergine, Peppers, Garlic, Mushrooms, Tomato</p>

          <h3>Pasta (Fish)</h3>
          <p><b>Penne Salmon</b>: Salmon, Philadelphia</p>
          <p>Linguine Seafood: Squid, Mussels, Prawns</p>

          <!-- <p>Tuna: Tuna, Lemon, Anchovies}</p>
          <p>Broccoli: Sausage, Broccoli}</p>
          <p>Kids: Tomato (Vegetarian)</p> -->
          <p><i>Gluten free pasta available (GF)</i></p>
        </div>
        
        <div>
          <h2>Aperitivo</h2>
          <p>Tapas: Patatas Bravas, Falafel, Garlic, Mushrooms, Fried Mozzarella, Calamari, Meatballs</p>
          <p>Antipasto Italiano: Parma Ham, Bresaola, Salami, Mortadella, Mozzarella</p>
        </div>

        <div>
          <h2>Cocktails</h2>
          <p>Espresso Martini</p>
          <p>Gin Tonic</p>
          <p>Aperol Spritz</p>
          <p>Capiroska</p>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
//import Footer from "@/components/Footer.vue";

export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";
@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

.content-wrapper{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


 p {
     color:darken(@setting_plaster,10%);
     margin:0;
 }
 ul {
   list-style-type: none;
   li{
     color:darken(@setting_plaster,10%);
   }
 }
</style>
