<template>
  <div class="home">
    <div class="content-wrapper">
      <h1>Our CAFE Menu</h1>
<a href="/menu/restaurant">Switch to RESTAURANT menu</a>

<div class="content-wrapper">
      <h2 id="breakfast">Breakfast</h2>

      <div>
        <h3 id="b1">Full English</h3>      
        <p>Meat: Sausage, Eggs, Bacon, Mushrooms, Tomato, Toast</p>
        <p>Vegetarian: Meat-free sausage, Eggs, Hash Browns, Mushrooms, Tomato, Toast(V)</p>
        <p>Vegan: Meat-free sausage, Avocado, Hash Browns, Mushrooms, Tomato, Toast(V)</p>
      </div>      

      <div>
        <h3 id="b2">Poached Eggs</h3>      
        <p>Avocado or Salmon</p>
      </div>      

      <div>
        <h3 id="b2">Pan Tumaca</h3>      
        <p>Toast with Parma Ham, tomato, garlic, Olive oil</p>
      </div>      

      <div>
        <h3 id="b2">Toast</h3>      
        <p>Bacon, Beans, Eggs, Sausage, Tomato, Avocado, Mushrooms</p>
      </div>      

      <div>
        <h3 id="b2">Breakfast Sandwich</h3>      
        <p>Bacon or Sausage</p>
      </div>      

<h2>Sandwiches - Panini </h2>
<div>
      <p>Grilled Chicken (halal) - Salad - Mayo</p>      
      <p>Ham - Avocado - Mustard</p>      
      <p>Salame - Mozzarella - Peppers</p>      
      <p>Bresaola - Parmigiano - Lemon - Salad</p>      
      <p>Mortadella - Provolone</p>
      <p>Ham - Provolone</p>
      <p>Salame - Gorgonzola</p>
      <p>Parma Ham - Mozzarella - Tomato - Artichokes - Olive oil</p>
      <p>Parma Ham - Parmigiano - Rocket - Olive oil</p>
      <p>Salmon - Philadelphia</p>
      <p>Avocado - Pesto - Peppers - Cucumber</p>
      <p>Halloumi - Harissa - Onion - Peppers</p>
      <p>Halloumi - Tomato - Pesto</p>
      <p>Halloumi - Beetroot - Houmous - Lemon</p>
      <p>Beetroot - Houmous - Carrot</p>
      <p>Feta - Salad</p>
      <p><i>Gluten free ciabatta available (GF)</i></p>
</div>

      <h2>Salad</h2>      
<div>
  <p>Caesar : Chicken (halal), Mixed salad, Parmigiano, Caesar sauce, Bread Crumbs</p>
  <p>Chicken : Chicken (halal), Mixed salad, Feta, Peppers</p>
  <p>Tuna: Tuna, Mixed Salad, Cucumber</p>
  <p>Greek: Tomatoes, Cucumber, Feta, Olives, Lettuce (Vegan)</p>
  <p>Bresaola: Bresaola, Rucola, Grana Flakes</p>
  <p>Italian: Mozzarella, Lettuce, Tomato</p>
  <p>Vegan: Avocado, Tomatoes, Rocket, Beetroot, Onion</p>
</div>      

<h2>Quick and Tasty</h2>
<div>
  <p>Jacket Potato: Beans and Cheese</p>
  <p>Spanish Tortilla</p>
  <p>Soup</p>
  <p>Rolls: Meat, Vegetarian and Vegan</p>
  <p>Falafel: Chickpeas, Garlic, Houmous, Pitta</p>
</div>

<div>
      <h2>Cakes</h2>      
        <p>Chocolate cake</p>
        <p>Carrot cake</p>
        <p>Banana loaf</p>
</div>      

      <h2 id="drinks">Drinks</h2>
<div>
      <h3>Fresh Juice</h3>      
        <p>Orange</p>
        <p>Orange-Carrot-Ginger</p>
        <p>Orange-Grapefruit</p>
</div>    
<div>
      <h3>Smoothies</h3>      
        <p>Pink: Yogurt, Banana, Raspberry, Beetroot</p>
        <p>Light pink: Yogurt, Banana, Strawberry, Cauliflower</p>
        <p>Orange: Yogurt, Banana, Orange, Carrot</p>
        <p>Purple: Yogurt, Banana, Red cabbage, Blueberry</p>
        <p>Green: Yogurt, Banana, Avocado, Spinach</p>
        <p>Yellow: Yogurt, Banana, Mango, Butternut</p>
</div>     

      <h2 id="alcohol">Alcohol</h2>
<div>
      <h3>White Vines</h3>      
        <p>Gavi</p>
        <p>Frascati</p>
        <p>Prosecco</p>
</div>
<div>      
      <h3>Red Vines</h3>
        <p>Nero d'Avola</p>
        <p>Primitivo</p>
</div>      
<div>
      <h3>Beer</h3>
        <p>Peroni</p>
        <p>Moretti</p>
</div>      

<div>
      <h3>Liquori</h3>
        <p>Amaro del Capo</p>
        <p>Limoncello</p>
        <p>Mirto</p>
</div>
<div>      
      <h3>Cocktails</h3>
        <p>Espresso Martini</p>
        <p>Caipiroska</p>
        <p>Aperol Spritz</p>
        <p>Gin Tonic</p>
</div>      

</div>
    </div>
  </div>
</template>

<script>
//import Footer from "@/components/Footer.vue";

export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";
@setting_plaster: #e0c9bc; //#e9dbd2;
@vardo: #336666; //#3e7a7c;
@gallery_red: #a0534b;
@red_earth: #1c1c1c;

.content-wrapper{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


 p {
     color:darken(@setting_plaster,10%);
     margin:0;
 }
 ul {
   list-style-type: none;
   li{
     color:darken(@setting_plaster,10%);
   }
 }
</style>
