var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content no-left-right",attrs:{"id":"products"}},[_c('h1',[_vm._v("Our menu")]),_c('div',{staticClass:"wrapper fade-1s"},[_c('div',{staticClass:"soup overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/soup.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#soup')}}},[_c('h1',[_vm._v("Soups")]),_c('p',[_vm._v("Healthy Mediteranean veggies")])])])]),_c('div',{staticClass:"juices overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/juices.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#drinks')}}},[_c('h1',[_vm._v("Juices")]),_c('p',[_vm._v("Made with fresh fruit")])])])]),_c('div',{staticClass:"sweet overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/IMG_20190914_161513.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#sweet')}}},[_c('h1',[_vm._v("Sweets")]),_vm._m(0)])])]),_c('div',{staticClass:"savoury overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/DSC_8313.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#savoury')}}},[_c('h1',[_vm._v("Savoury")]),_vm._m(1)])])]),_c('div',{staticClass:"breakfast overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/breakfast.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#breakfast')}}},[_c('h1',[_vm._v("Breakfast")]),_c('p',[_vm._v("English breakfast with an italian twist")]),_c('p',[_vm._v("Available also for vegans")])])])]),_c('div',{staticClass:"coffee overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/coffee.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#coffee')}}},[_c('h1',[_vm._v("Coffee")]),_c('p',[_vm._v("Directly from Naples")])])])]),_c('div',{staticClass:"aperitivo overlay card"},[_c('div',{staticClass:"normal"},[_c('div',{staticClass:"cover-image",style:({
            'background-image': `url(${require('@/assets/400/aperitivo.jpg')})`
          })})]),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.$router.push('menu#aperitivo')}}},[_c('h1',[_vm._v("Aperitivo")]),_c('p',[_vm._v("Free food with your drink. Exaclty like in Milan.")])])])])]),_c('a',{staticClass:"button",attrs:{"href":"/menu","target":"_blank"}},[_vm._v("Full menu")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Croissants")]),_c('li',[_vm._v("Cakes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Pasta to go")]),_c('li',[_vm._v("Panini")]),_c('li',[_vm._v("Pizza - Bruschetta")]),_c('li',[_vm._v("Salad")])])
}]

export { render, staticRenderFns }