<template>
  <div class="content no-left-right" id="social">
    <!-- <div class="content-section fade-1s" v-in-viewport.once> -->
    <div class="content-section fade-1s" >
      <h1>Social Media</h1>
      <p>Don't forget to like and follow us
      on our <a href="/review">SOCIAL MEDIA</a>!</p>

      <!-- <div id="fb-root"></div>
      <div
        class="fb-like"
        data-href="https://www.facebook.com/vapensierolounge/"
        data-width=""
        data-layout="button_count"
        data-action="like"
        data-size="large"
        data-share="true"
      ></div>
</hr>

      <div id="TA_cdswritereviewnew490" class="TA_cdswritereviewnew">
        <ul id="8RhPhNPwDtae" class="TA_links GHoiwr"><li id="Vb0m0DWb" class="ye4q3rg6"><a target="_blank" href="https://www.tripadvisor.co.uk/"><img src="https://www.tripadvisor.co.uk/img/cdsi/img2/branding/medium-logo-29834-2.png" alt="TripAdvisor"/></a></li></ul></div> -->

      <!-- <div id="TA_cdswritereviewlg842" class="TA_cdswritereviewlg">
        <ul id="ryBDnt9eD9y" class="TA_links fkvVRMbr">
          <li id="S2MQKcWl" class="NhSU1XSwO">
            <a target="_blank" href="https://www.tripadvisor.co.uk/"
              ><img
                src="https://www.tripadvisor.co.uk/img/cdsi/img2/branding/medium-logo-12097-2.png"
                alt="TripAdvisor"
            /></a>
          </li>
        </ul>
      </div> -->

      <div id="curator-feed-default-layout">
        <a href="https://curator.io" target="_blank" class="crt-logo crt-tag"
          >Powered by Curator.io</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Facebook",

  mounted() {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://cdn.curator.io/published/27e49ca8-28c3-4f2c-8503-3a06eb5b2f66.js"
    );
    //script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", true);
    script.setAttribute("defer", true);
    document.head.appendChild(script);

    // let facebook = document.createElement("script");
    // facebook.setAttribute(
    //   "src",
    //   "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0&appId=2451614975098935&autoLogAppEvents=1"
    // );
    // facebook.setAttribute("crossorigin", "anonymous");
    // facebook.setAttribute("async", true);
    // facebook.setAttribute("defer", true);
    // document.head.appendChild(facebook);

    // let tripadvisor = document.createElement("script");
    // tripadvisor.setAttribute(
    //   "src",
    //   "https://www.jscache.com/wejs?wtype=cdswritereviewlg&amp;uniq=842&amp;locationId=17729751&amp;lang=en_UK&amp;lang=en_UK&amp;display_version=2"
    // );
    // tripadvisor.setAttribute("type", "text/javascript");
    // tripadvisor.setAttribute("onload", "this.loadtrk=true");
    // tripadvisor.setAttribute("async", true);
    // tripadvisor.setAttribute("data-loadtrk", true);
    // document.body.appendChild(tripadvisor);
  }
};



// <div id="TA_cdswritereviewnew576" class="TA_cdswritereviewnew"><ul id="I4nFNA0T" class="TA_links vrxjNPwk"><li id="6JvaxF2eij2o" class="xpPNF24LpeJJ"><a target="_blank" href="https://www.tripadvisor.co.uk/"><img src="https://www.tripadvisor.co.uk/img/cdsi/img2/branding/medium-logo-29834-2.png" alt="TripAdvisor"/></a></li></ul></div><script async src="https://www.jscache.com/wejs?wtype=cdswritereviewnew&amp;uniq=576&amp;locationId=17729751&amp;lang=en_UK&amp;lang=en_UK&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
// </script>

<style scoped lang="less">
@import "../assets/css/breakpoints.less";

a{
  color:cyan;
}

.content{
  //background-color:white;
}

.content.no-left-right {
  padding-right: 0px;
  padding-left: 0px;
}

.image-wrapper {
  min-height: 300px;
}
</style>
